import { Link } from 'react-router-dom';
import bfailogo from '../../assets/bfailogo.png';

function Footer() {
    return (
        <footer className="mt-10 bg-gray-100">
            <div className="max-w-4xl mx-auto py-6 px-4 flex justify-between items-start flex-wrap">
                <div className="flex items-center mb-4">
                    <img src={bfailogo} alt="Bright Future AI Services Logo" className="mr-3 h-16" />
                </div>
                <div className="flex flex-col gap-2">
                    <span className="text-sm text-black-600">Phone: +36209999129</span>
                    <span className="text-sm text-black-600">Phone: +61488663777</span>
                    <a href="mailto:info@bf-ai.com" className="text-sm text-black-600 hover:underline">info@bf-ai.com</a>
                </div>
                <div className="flex flex-col gap-2">
                    <Link to="/privacy-policy" className="text-sm text-black hover:underline">Privacy Policy</Link>
                    <a href="https://bf-ai.com" className="text-sm text-black-600 hover:underline">Powered by Bright Future AI</a>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
