const DownloadApp: React.FC = () => {
    const navigateToiOSDownload = () => {
        window.location.href = 'https://apps.apple.com/hu/app/ask-qua/id6476885126';
    };

    const navigateToAndroidDownload = () => {
        window.location.href = 'https://play.google.com/store/apps/details?id=com.askqua.bfai&pli=1';
    };

    return (
        // Assuming max-w-4xl is the size of the Profile Selection component for consistency
        <div className="max-w-4xl mx-auto mt-8 bg-white rounded-lg shadow-md p-6 border border-green-500" id="app">
            <h2 className="text-lg font-semibold mb-4">Step 6: Download our app and you're ready to talk with Miss QuA</h2>
            <p className="mb-4">After you downloaded the app you can select your profile and try it out.</p>
            <div className="flex gap-4">
                {/* Adjusting button styles for a green theme similar to the Profile Selection component */}
                <button onClick={navigateToiOSDownload} className="py-2 px-4 bg-blue-600 hover:bg-blue-700 text-white font-bold rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition duration-150 ease-in-out">
                    Download for iOS
                </button>
                <button onClick={navigateToAndroidDownload} className="py-2 px-4 bg-green-600 hover:bg-green-700 text-white font-bold rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition duration-150 ease-in-out">
                    Download for Android
                </button>
            </div>
        </div>
    );
};

export default DownloadApp;
