import React, { useEffect, useState, useRef} from 'react';
import { toast } from 'react-toastify';
import logo from '../../assets/missqua.png';
import Loading from '../utils/Loading';
import { useAuth } from '../../hooks/AuthState';
import { useNavigate, useLocation } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { Token } from '../../types/Token';
import { fetchProfileLogin, getProfileParametersLogin } from '../../services/ProfileService';
import { ProfileSelectionDTO } from '../../types/ProfileSelectionDTO';


const Login: React.FC = () => {
    const [username, setUsername] = useState('');
    const [usernameIsAvailable, setUsernameIsAvailable] = useState(true);
    const [companyDetailsIsAvailable, setCompanyDetailsIsAvailable] = useState(false);
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [companyName, setCompanyName] = useState('');
    const [assistantPosition, setAssistantPosition] = useState('');
    const [profileName, setProfileName] = useState('');
    const  { loginCall,accessToken, isAuthenticated } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    const fetchedRef = useRef(false);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const usernameFromUrl = params.get('username');

        // Only proceed if username is in the URL and it's not already set to the state
        if (usernameFromUrl && usernameFromUrl !== username) {
            setUsername(usernameFromUrl);
            setUsernameIsAvailable(false);
            setCompanyDetailsIsAvailable(true);
            // Check if we've already fetched data for this username to prevent re-fetching
            if (!fetchedRef.current) {
                const fetchData = async () => {
                    try {
                        setLoading(true);
                        const profile = await fetchProfileLogin(usernameFromUrl) as ProfileSelectionDTO;
                        if (profile) {
                            setProfileName(profile.name + ' - ' + profile.saved_name);
                            const parameters = await getProfileParametersLogin(profile.id);
                            console.log(parameters);
                            setCompanyName(parameters.company_name);
                            setAssistantPosition(parameters.digital_assistant);
                        } else {
                            console.error('Profile not found.');
                        }
                    } catch (error) {
                        console.error('Failed to fetch data:', error);
                    }finally {
                        setLoading(false);
                    }
                };

                fetchData();
                fetchedRef.current = true; // Mark as fetched
            }
        } else {
            fetchedRef.current = false; // Reset if username from URL is absent or changed
        }
    }, [location.search]);  

    const handleLogin = async () => {
        if(username == '') {toast.info('Please enter your username!'); return;}
        //if(password == '') {toast.info('Please enter your password!'); return;}
        setLoading(true);
        loginCall(username, password).then(() => {
            const role = (jwtDecode(localStorage.getItem('token') as string) as Token).role;
            if(role === 'admin') navigate('/admin');
            else navigate('/');
            toast.success('Logged in successfully!');
        }).catch((error) => {
            toast.error('Username or password is incorrect!');
            console.error('Error logging in:', error);
        }).finally(() => {
            setLoading(false);
        });
    };

    useEffect(() => {
        if (isAuthenticated) {
            if(accessToken?.role === 'admin') navigate('/admin');
            else navigate('/'); 
        }
      }, [isAuthenticated, navigate]);
    
    if (loading) {
        return <Loading />;
    }

    return (
        <div className="flex max-w-2xl mx-auto my-10 bg-white rounded-lg shadow-lg overflow-hidden border border-teal-500">
            <div className="hidden sm:flex w-full md:w-1/3 bg-teal-500 text-white p-8 flex flex-col justify-center items-center">
                <img src={logo} alt="Logo" className="w-32 h-32 rounded-full shadow-lg mb-4" />
                <h2 className="text-xl font-semibold">Welcome!</h2>
            </div>
            <div className="w-full md:w-100 p-6 space-y-4">
                <h2 className="text-xl font-semibold text-gray-900">Login to Your Account</h2>
                {usernameIsAvailable && 
                    <div>
                        <label htmlFor="username-input" className="block text-gray-700 font-medium">Username:</label>
                        <input
                            type="text"
                            id="username-input"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            placeholder="Enter your username"
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500 text-base"
                        />
                    </div>
                }
                {companyDetailsIsAvailable &&
                    <div className="lg:text-lg lg:mb-4">
                    <label htmlFor="company-profile" className="block text-gray-700 font-medium mb-2">{profileName}</label>
                    <label htmlFor="company-details-input" className="block text-gray-700 font-medium mb-2">Company Details:</label>
                    <div className="text-gray-600 text-sm">
                        <div className="mb-1"><strong className="font-semibold">Company Name:</strong> {companyName}</div>
                        <div><strong className="font-semibold">Position:</strong> {assistantPosition}</div>
                    </div>
                </div>
                }
                <div>
                    <label htmlFor="password-input" className="block text-gray-700 font-medium">Password:</label>
                    <input
                        type="password"
                        id="password-input"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Enter your password"
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500 text-base"
                    />
                </div>
                <div className="flex justify-end">
                    <button
                        type='button'
                        onClick={handleLogin}
                        disabled={loading}
                        className="py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                    >
                        {loading ? 'Logging in...' : 'Log In'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Login;
