import { createContext, useContext, useState, ReactNode } from 'react';
import { ProfileSelectionDTO } from '../types/ProfileSelectionDTO';

interface ProfileContextType {
  selectedProfileId: string;
  profile: ProfileSelectionDTO | undefined;
  setSelectedProfileId: (id: string) => void;
  setProfile: (profile: ProfileSelectionDTO) => void;
  pdfFormat: string;
  setPdfFormat: (format: string) => void;
  profileName: string;
  setProfileName: (name: string) => void;
}

const ProfileContext = createContext<ProfileContextType | undefined>(undefined);

// Define a type for the props including children
interface ProfileProviderProps {
  children: ReactNode; // Accepts any valid React child (element, string, etc.)
}

export const ProfileProvider: React.FC<ProfileProviderProps> = ({ children }) => {
  const [selectedProfileId, setSelectedProfileId] = useState('select-profile');
  const [profile, setProfile] = useState<ProfileSelectionDTO>();
  const [pdfFormat, setPdfFormat] = useState('select-style');
  const [profileName, setProfileName] = useState('');

  return (
    <ProfileContext.Provider value={{ selectedProfileId, setSelectedProfileId, profile, setProfile, pdfFormat, setPdfFormat, profileName, setProfileName }}>
      {children}
    </ProfileContext.Provider>
  );
};

export const useProfile = () => {
  const context = useContext(ProfileContext);
  if (context === undefined) {
    throw new Error('useProfile must be used within a ProfileProvider');
  }
  return context;
};
