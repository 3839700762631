import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { fetchUploadedFiles, fetchGeneratedFiles, getJSONFormat, getCompleteJson, saveJson, getGeneratedJson, activateDocuments } from '../../services/FileService';
import { useProfile } from '../../contexts/ProfileContextType';
import { GeneratedFileDTO } from '../../types/GeneratedFileDTO';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { BiShow } from "react-icons/bi"
import Loading from '../utils/Loading';
import Modal from '../modals/JsonViewer';
import DeleteConfirmation from '../modals/DeleteConfirmation';
import { saveProfile } from '../../services/ProfileService';

const ContentManager: React.FC = () => {
    const { selectedProfileId, setPdfFormat, pdfFormat,profileName } = useProfile();
    const [uploadedFiles, setUploadedFiles] = useState<string[]>([]);
    const [generatedFiles, setGeneratedFiles] = useState<GeneratedFileDTO[]>([]);
    const [selectedGeneratedFiles, setSelectedGeneratedFiles] = useState<string[]>([]);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [loading, setLoading] = useState(false);
    const [json, setJson] = useState<any>(null);
    const [checkedItems, setCheckedItems] = useState<{ [key: string]: { checked: boolean, text: string } }>({});
    const [fetchedJsonFileName, setFetchJsonFileName] = useState<string>('');
    const [fetchJsonFileTitle, setFetchJsonFileTitle] = useState<string>('');
    const [modalContent, setModalContent] = useState({});
    const [isJsonModalVisible, setIsJsonModalVisible] = useState(false);
    const [isDeleteConfirmationVisible, setIsDeleteConfirmationVisible] = useState(false);
    const [deletedFile, setDeletedFile] = useState<string>('');
    const [isUploadedFilesLoading, setIsUploadedFilesLoading] = useState(true);

    useEffect(() => {
        if (selectedProfileId === 'select-profile') return;
        setIsUploadedFilesLoading(true);
        fetchUploadedFiles(selectedProfileId)
            .then((data) => { setUploadedFiles(data.files); })
            .catch((error) => {
                toast.error('Failed to fetch uploaded files');
            })
            .finally(() => setIsUploadedFilesLoading(false));
        fetchGeneratedFiles(selectedProfileId)
            .then((data) => {
                setGeneratedFiles(data.files);
                setSelectedGeneratedFiles(data.files.filter((file: { activated: any; }) => file.activated).map((file: { file: any; }) => file.file));
            })
            .catch((error) => {
                toast.error('Failed to fetch generated files');
            });
    }, [selectedProfileId]);

    useEffect(() => {
        if (json) {
            const initializeCheckedState = (data: any, parentKey = '') => {
                const entries: { [key: string]: { checked: boolean, text: string } } = {};
                if (data[0] instanceof Array) {
                    entries['DocumentTitle'] = { checked: true, text: data[0][0].DocumentTitle };
                    setFetchJsonFileTitle(data[0][0].DocumentTitle);
                }
                else {
                    entries['DocumentTitle'] = { checked: true, text: data[0].DocumentTitle };
                    setFetchJsonFileTitle(data[0].DocumentTitle);
                }

                const addEntries = (obj: object, path: string) => {
                    Object.entries(obj).forEach(([key, value], index) => {
                        if (key === 'DocumentTitle') {
                            return;
                        }
                        // Splitting the path and concatenating the current key, filtering out numeric segments
                        let segments = path.split('.').concat(key).filter(segment => isNaN(Number(segment)));
                        let fullKey = segments.join('.');
                        // Determine if the object is top-level based on the path
                        let isTopLevel = path === '';
                        // Set checked based on whether the key includes 'description'
                        // Set text to an empty string if it's a top-level object, or to the value otherwise
                        entries[fullKey] = { checked: fullKey.toLowerCase().includes('description'), text: isTopLevel && !fullKey.toLowerCase().includes('description') ? "" : value };
                        // If the current value is an object and not null, recurse to add its entries
                        if (typeof value === 'object' && value !== null) {
                            addEntries(value, fullKey);
                        }
                    });
                };

                addEntries(data, parentKey);
                setCheckedItems(entries);
            };

            initializeCheckedState(json);
        }
    }, [json]);

    useEffect(() => {
        if (!isUploadedFilesLoading && uploadedFiles.length === 0) {
            toast.info('No uploaded files. Please select a PDF type and then save.');
            setPdfFormat('select-style');
            saveProfile(selectedProfileId, profileName, 'select-style');

            const element = document.getElementById('pdf-format-selection');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
    }, [uploadedFiles]);


    const handleFileUpload = async () => {
        if (!selectedFile) {
            toast.error("Please select a file first.");
            return;
        }
        try {
            setLoading(true);
            const data = await getJSONFormat(selectedProfileId, selectedFile);
            toast.success(`${selectedFile.name} processed successfully!`);
            fetchCompleteJson(selectedFile.name);
            fetchFileLists();
        } catch (error) {
            toast.error('Failed to process file');
        } finally {
            setLoading(false);
        }
    };

    const fetchFileLists = async () => {
        fetchUploadedFiles(selectedProfileId)
            .then((data) => setUploadedFiles(data.files))
            .catch((error) => {
                toast.error('Failed to fetch uploaded files');
            })
        fetchGeneratedFiles(selectedProfileId)
            .then((data) => {
                setGeneratedFiles(data.files);
                setSelectedGeneratedFiles(data.files.filter((file: { activated: any; }) => file.activated).map((file: { file: any; }) => file.file));
            })
            .catch((error) => {
                toast.error('Failed to fetch generated files');
            });
    }

    const toggleFileSelection = (file: GeneratedFileDTO) => {
        setSelectedGeneratedFiles((prevSelected) => {
            if (prevSelected.includes(file.file)) {
                // If file is already selected, remove it from the selection
                return prevSelected.filter(f => f !== file.file);
            } else {
                // File is not selected, add it to the selection
                return [...prevSelected, file.file];
            }
        });
    };


    const fetchCompleteJson = async (filename: string) => {
        try {
            filename = filename.split('.').slice(0, -1).join('.');
            setFetchJsonFileName(filename);
            const data = await getCompleteJson(selectedProfileId, filename);
            setJson(data);
            const element = document.getElementById('input-manager');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
            return data;
        } catch (error) {
            toast.error('Failed to fetch complete JSON');
            throw error;
        }
    };

    const handleSave = () => {
        const constructJsonFromCheckedItems = () => {
            const result = {};

            // Function to recursively construct the JSON structure
            const assignValueToPath = (path: string, value: string, obj: any) => {
                const keys = path.split('.');
                let current = obj;

                for (let i = 0; i < keys.length - 1; i++) {
                    const key = keys[i];
                    // If the key doesn't exist or isn't an object, create/overwrite it
                    if (!current[key] || typeof current[key] !== 'object') {
                        current[key] = {};
                    }

                    current = current[key];
                }

                // Assign the value to the final key
                current[keys[keys.length - 1]] = value;
            };

            // Filter checked items and reconstruct the JSON structure
            Object.entries(checkedItems).forEach(([key, { checked, text }]) => {
                if (checked && key !== 'DocumentTitle') {
                    assignValueToPath(key, text, result);
                }
            });

            return {
                DocumentTitle: fetchJsonFileTitle,
                Content: result
            };
        };

        const savedJson = constructJsonFromCheckedItems();
        setLoading(true);
        saveJson(selectedProfileId, fetchedJsonFileName, savedJson).then(() => {
            fetchFileLists();
            toast.success('Selections saved successfully!');
            setTimeout(() => {
                const element = document.getElementById('activate-documents');
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
            }, 500);
        }).catch((error) => {
            toast.error('Failed to save selections');
        }).finally(() => setLoading(false));
    };

    const handleActivate = () => {
        if(pdfFormat === 'select-style') {
            toast.info('Please select a profile style first and save it!');
            const element = document.getElementById('pdf-format-selection');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
            return;
        }
        if (selectedGeneratedFiles.length === 0) {
            toast.info('Please select at least one document to activate');
            return;
        }
        setLoading(true);
        activateDocuments(selectedProfileId, selectedGeneratedFiles, pdfFormat).then(() => {
            fetchFileLists();
            setTimeout(() => {
                const appElement = document.getElementById('app');
                if (appElement) {
                    appElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
            }, 500);
            toast.success('Documents activated successfully!');
        }).catch((error) => {
            toast.error('Failed to activate documents');
        }).
            finally(() => setLoading(false));
    };

    const handleCheckboxChange = (fullKey: string, isChecked: boolean) => {
        setCheckedItems(prev => {
            const updated = { ...prev };

            // Directly update the checked state of the current item
            updated[fullKey] = { checked: isChecked, text: updated[fullKey].text };

            // Recursively update children, if any
            const updateChildren = (keyPrefix: string, checkState: boolean) => {
                Object.keys(prev).forEach(key => {
                    if (key.startsWith(`${keyPrefix}.`)) {
                        updated[key] = { checked: checkState, text: updated[key].text };
                    }
                });
            };

            // Update children
            updateChildren(fullKey, isChecked);

            // Function to recursively check and update parent based on children's state
            const updateParentBasedOnChildren = (childKey: string) => {
                const segments = childKey.split('.');
                segments.pop(); // Remove the last segment to get the parent key
                const parentKey = segments.join('.');

                if (parentKey) {
                    const childrenKeys = Object.keys(prev).filter(key => key.startsWith(`${parentKey}.`) && key !== parentKey);
                    const allChildrenChecked = childrenKeys.length > 0 && childrenKeys.every(key => updated[key]);

                    updated[parentKey] = { checked: allChildrenChecked, text: updated[parentKey].text };

                    // Recursively update higher level parents if any
                    updateParentBasedOnChildren(parentKey);
                }
            };

            // Update parent(s) based on children's state
            updateParentBasedOnChildren(fullKey);

            return updated;
        });
    };

    const generateUniqueKey = (parentKey: string, key: any, index: any) => {
        return `${parentKey ? parentKey + '.' : ''}${key}-${index}`;
    };

    const createCheckboxes = (data: any, level = 0, parentKey = '', isUnderDescription = false): JSX.Element[] => {
        return Object.entries(data).reduce<JSX.Element[]>((acc, [key, value], index) => {
            if (key === 'DocumentTitle') {
                return acc;
            }

            const isArrayElement = Array.isArray(data);
            const fullKey = isArrayElement ? parentKey : `${parentKey ? `${parentKey}.` : ''}${key}`;
            const isDescription = key.toLowerCase().includes('description') || (typeof value === 'string' && value.toLowerCase().includes('description'));
            const disableChildren = isDescription || isUnderDescription;
            const uniqueKey = generateUniqueKey(parentKey, key, index);

            let displayLabel = isArrayElement ? value : `${key}: ${value}`;

            // For non-primitive values (i.e., objects and arrays), don't use them directly as labels
            if (typeof value === 'object' && value !== null) {
                displayLabel = isArrayElement ? 'Array' : `${key}`; // Adjusted for clarity
                // For objects (including arrays), recursively add child checkboxes
                const childCheckboxes = createCheckboxes(value, level + 1, fullKey, disableChildren);
                acc.push(
                    <div key={uniqueKey} className={`ml-${level * 5} mb-2`} style={{ marginLeft: `${level * 10}px` }}>
                        {!isArrayElement && (
                            <label className="flex items-center space-x-2">
                                <input
                                    type="checkbox"
                                    className="form-checkbox h-4 w-4 text-teal-600 border-gray-300 rounded focus:ring-teal-500"
                                    checked={!!checkedItems[fullKey]?.checked || disableChildren}
                                    onChange={(e) => handleCheckboxChange(fullKey, e.target.checked)}
                                    disabled={disableChildren}
                                />
                                <span className={`${disableChildren ? 'text-gray-400' : 'text-gray-700'} text-sm`}>{displayLabel as React.ReactNode}</span>
                            </label>
                        )}
                        {childCheckboxes}
                    </div>
                );
            } else {
                // Primitive value or the label for arrays
                acc.push(
                    <div key={uniqueKey} className={`ml-${level * 5} mt-2`} style={{ marginLeft: `${level * 10}px` }}>
                        <label className="flex items-center space-x-2">
                            <input
                                type="checkbox"
                                className="form-checkbox h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500 disabled:bg-gray-200"
                                checked={!!checkedItems[fullKey]?.checked || disableChildren}
                                onChange={(e) => handleCheckboxChange(fullKey, e.target.checked)}
                                disabled={disableChildren}
                            />
                            <span className={`text-sm ${disableChildren ? 'text-gray-500' : 'text-gray-700'}`}>{displayLabel as React.ReactNode}</span>
                        </label>
                    </div>
                );
            }
            return acc;
        }, []);
    };

    const handleSelectAll = () => {
        const updated = { ...checkedItems };
        Object.keys(updated).forEach(key => {
            updated[key] = { checked: true, text: updated[key].text };
        });
        setCheckedItems(updated);
    };

    const handleGetGeneratedJson = async (filename: string) => {
        try {
            const data = await getGeneratedJson(selectedProfileId, filename);
            data.file_content = JSON.parse(data.file_content);
            setModalContent(data.file_content);
            setIsJsonModalVisible(true);
        } catch (error) {
            toast.error('Failed to fetch generated JSON');
        }
    };
    const handleFileDeletion = (file: string) => {
        setDeletedFile(file);
        setIsDeleteConfirmationVisible(true);
    }

    if (loading) {
        return <Loading />;
    }

    return (
        <div className="max-w-4xl mx-auto my-10">
            {/* Combined Step 3 and Step 5 in a grid layout */}
            <div className="bg-white rounded-lg shadow-md p-6 border border-teal-500 md:grid md:grid-cols-2 md:gap-6">
                {/* Document Management - Step 3 */}
                <div className="bg-white rounded-lg shadow-md p-6 border border-teal-500">
                    <h3 className="text-lg font-semibold mb-4">Step 3: Document Management</h3>
                    <div className="space-y-2 mb-2">
                        {uploadedFiles.map((file, index) => (
                            <div key={index} className="flex justify-between items-center p-2 bg-gray-100 rounded-lg">
                                <button onClick={() => fetchCompleteJson(file)} className="text-sm flex-1 text-left">{file}</button>
                                <RiDeleteBin6Line onClick={() => handleFileDeletion(file)} className="cursor-pointer text-red-500 ml-4" />
                            </div>
                        ))}
                    </div>
                    <DeleteConfirmation profileId={selectedProfileId} file={deletedFile} fetchFileLists={fetchFileLists} isVisible={isDeleteConfirmationVisible} onClose={() => setIsDeleteConfirmationVisible(false)} />
                    <input type="file" onChange={e => setSelectedFile(e.target.files ? e.target.files[0] : null)} className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100" />
                    <button onClick={handleFileUpload} className="mt-4 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                        id="document-management"
                    >
                        Upload and Process File
                    </button>
                </div>

                {/* Activate Selected Forms - Step 5 */}
                <div className="bg-white rounded-lg shadow-md p-6 border border-teal-500">
                    <h3 className="text-lg font-semibold mb-4" id="activate-documents">Step 5: Activate Selected Forms</h3>
                    <div className="space-y-2">
                        {generatedFiles.map((file, index) => (
                            <div key={index} className="flex justify-between items-center p-2 bg-gray-100 rounded-lg">
                                <input type="checkbox" checked={selectedGeneratedFiles.includes(file.file)} onChange={() => toggleFileSelection(file)} className="mr-2" />
                                <BiShow onClick={() => handleGetGeneratedJson(file.file)} className="cursor-pointer text-blue-500 mr-2" />
                                <Modal
                                    content={modalContent}
                                    isVisible={isJsonModalVisible}
                                    onClose={() => setIsJsonModalVisible(false)}
                                />
                                <span className="text-sm flex-1 text-left">{file.file}</span>
                            </div>
                        ))}
                    </div>
                    <button onClick={handleActivate} className="mt-4 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                    >
                        Activate Documents
                    </button>
                </div>
            </div>

            {/* Managing Content in Form Inputs - Step 4 */}
            <div className="bg-white rounded-lg shadow-md p-6 border border-teal-500 mt-10">
                <h3 className="text-lg font-semibold mb-4">Step 4: Managing Content in Form Inputs</h3>
                <div className="gap-4 mb-4 flex items-center">
                    <button onClick={handleSelectAll} className="mb-4 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                    >
                        Select all
                    </button>
                    <button onClick={handleSave} className="mb-4 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                    id="input-manager"
                    >
                        Save Selections
                    </button>
                </div>
                <h4 className="text-lg font-semibold mb-4">{fetchJsonFileTitle}</h4>
                <div id="checkbox-container">
                    {json && createCheckboxes(json)}
                </div>
            </div>
        </div>
    );
};

export default ContentManager;
